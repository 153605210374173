import { AppSettingsModel } from "@/@types/model";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { Get } from "vuex-pathify";
import { validationMixin } from 'vuelidate'

import authService, { LoginResponse } from "../services/auth.service";

@Component
export default class Login extends mixins(validationMixin) {
  email: string = "";
  password: string = "";
  remember: boolean = false;

  isBusy: boolean = false;
  errorCode: string | null = null;

  @Get("app/settings")
  appSettings!: AppSettingsModel;

  async login() {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    this.isBusy = true;
    this.errorCode = null;
    try {
      // Submit the form.
      const email = this.email.toLowerCase();
      const password = this.password;
      const data = await authService.login(email, password);

      if (data) await this._endLogin(data);
    } catch (error) {
      const response = error.response;
      if (response) {
        const { code } = response.data || {};
        this.errorCode = code || "AUTH_ERROR";
      }
    } finally {
      this.isBusy = false;
    }
  }

  async _endLogin(data: LoginResponse) {
    // Save the token.
    await this.$store.dispatch("auth/saveToken", {
      token: data.token,
      remember: this.remember,
    });

    // Fetch the user.
    await this.$store.dispatch("auth/fetchUser");
    // Fetch the app settings.
    await this.$store.dispatch("app/fetchSettings");

    // Redirect dashboard OR settings.
    if (!this.appSettings.isFirstConfig) await this.$router.push({ name: "dashboard" });
    else await this.$router.push({ name: "settings.first" });
  }
}
