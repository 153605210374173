<template>
  <div id="register">
    <v-card class="register-card" elevation="2">
      <v-toolbar class="register-card__toolbar" flat>
        <v-toolbar-title> {{ $t("Auth.Registration") }} </v-toolbar-title>
      </v-toolbar>

      <v-divider />

      <v-card-text>
        <template v-if="mustVerifyEmail">
          <v-alert text outlined color="info" icon="mdi-information-outline">
            {{ $t("Auth.WeSentYouVerificationLinkSent") }}
          </v-alert>
          <v-btn class="mt-4 mr-4" @click="login" color="primary" :loading="isBusy" block>
            {{ $t("GlobalUI.Next") }}
          </v-btn>
        </template>
        <form v-else class="register-form" @submit.prevent="register">
          <h4 class="subtitle-1 font-weight-medium">{{ $t("Auth.AccountDetails") }}</h4>
          <v-divider class="my-2" />
          <v-text-field
            v-model="email"
            prepend-icon="mdi-email"
            :label="$t('Auth.Email')"
            :error-messages="emailErrors"
          />

          <v-text-field
            v-model="password"
            prepend-icon="mdi-lock"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('Auth.Password')"
            :error-messages="passwordErrors"
            hide-details="auto"
          >
            <template v-slot:append-outer>
              <v-btn tabindex="-1" @click="showPassword = !showPassword" text small>
                {{ !showPassword ? $t("GlobalUI.Show") : $t("GlobalUI.Hide") }}
              </v-btn>
            </template>
          </v-text-field>

          <password-strength
            class="password-strength"
            v-model="password"
            :secureLength="PASSWORD_MAX_LENGTH"
            :strength-meter-only="true"
            @score="(score) => (this.passwordStrength = score)"
          />

          <h4 class="subtitle-1 font-weight-medium mt-4">{{ $t("Auth.CompanyDetails") }}</h4>
          <v-divider class="my-2" />

          <v-text-field
            v-model="supplierTitle"
            prepend-icon="mdi-silverware"
            :label="$t('Auth.RestaurantOrHotelName')"
            :error-messages="supplierTitleErrors"
          />

          <v-text-field
            v-model="legalCompanyName"
            prepend-icon="mdi-domain"
            :label="$t('Auth.LegalCompanyName')"
            :error-messages="legalCompanyNameErrors"
          />

          <v-btn class="mt-6" type="submit" color="primary" :loading="isBusy" block>
            {{ $t("Auth.Register") }}
          </v-btn>
        </form>

        <v-alert
          v-if="errorCode"
          class="mt-5"
          text
          outlined
          color="deep-orange"
          icon="mdi-fire"
          dismissible
        >
          {{ $t(`ServerResponse.${errorCode}`) }}
        </v-alert>
      </v-card-text>
    </v-card>
    <div class="d-flex flex-column flex-sm-row justify-center align-center mt-4">
      <span>{{ $t("Auth.HaveAnAccount") }}</span>
      <router-link :to="{ name: 'login' }" class="ml-0 mt-2 ml-sm-4 mt-sm-0">
        <b>{{ $t("Auth.SignIn") }}</b>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

import PasswordStrength from "vue-password-strength-meter";

import { email, PASSWORD_MAX_LENGTH } from "../../services/validators.service";
import login from "../../mixins/login";

import { API_URL } from "../../config";

export default {
  name: "Register",

  components: { PasswordStrength },

  middleware: "guest",

  layout: "auth",

  metaInfo() {
    return { title: "Register" };
  },

  mixins: [validationMixin, login],

  validations: {
    email: { required, email },
    password: {
      required,
      minLength: minLength(PASSWORD_MAX_LENGTH),
      strength: (_, vm) => vm.passwordStrength >= 2,
    },
    supplierTitle: { required },
    legalCompanyName: { required },
  },

  data: () => ({
    PASSWORD_MAX_LENGTH,

    email: "",
    password: "",
    showPassword: false,
    passwordStrength: 0,

    supplierTitle: "",
    legalCompanyName: "",

    mustVerifyEmail: false,

    isBusy: false,
    errorCode: null,
  }),

  computed: {
    ...mapGetters({
      check: "auth/check",
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push(this.$t("Validation.MailRequired"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push(this.$t("Validation.FieldRequired"));
      !this.$v.password.minLength && errors.push(this.$t("Validation.PasswordHelper"));
      !this.$v.password.strength && errors.push(this.$t("Validation.WeakPassword"));
      return errors;
    },
    supplierTitleErrors() {
      const errors = [];
      if (!this.$v.supplierTitle.$dirty) return errors;
      !this.$v.supplierTitle.required && errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },
    legalCompanyNameErrors() {
      const errors = [];
      if (!this.$v.legalCompanyName.$dirty) return errors;
      !this.$v.legalCompanyName.required && errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },
  },

  created() {
    if (this.check) this.$store.dispatch("auth/clearUser");
  },

  methods: {
    async register() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isBusy = true;
      this.errorCode = null;
      try {
        // Register the user.
        await axios.post(
          `${API_URL}/register`,
          {
            email: this.email.toLowerCase(),
            password: this.password,
            supplierTitle: this.supplierTitle,
            legalCompanyName: this.legalCompanyName,
          },
          { config: { showNotify: false } },
        );

        await this.$store.dispatch("auth/updateUser", {
          isFirstVisit: true,
          isFirstConfig: true,
        });

        this.mustVerifyEmail = true;
      } catch (error) {
        const response = error.response;
        if (response) {
          const { code } = response.data || {};
          this.errorCode = code || "REGISTRATION_ERROR";
        }
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password-strength {
  max-width: 100%;
  margin-left: 33px;
}
</style>
